import React, { useState, useEffect } from 'react';
import { DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { RoundedDialog, StandardDialogActions, RichTextField, HtmlText } from './index';
import { ContainsHTMLTags } from '../Functions';

export default function TextDialog({ open, initial, editable, onClose, onResult, title, required }) {
  const [text, setText] = useState('');
  const [isHtml, setIsHtml] = useState(false);

  useEffect(() => {
    if (!open) return;
    const value = initial || '';
    setText(value);
    setIsHtml(ContainsHTMLTags(value));
  }, [initial, open]);

  function done() {
    onResult(text);
    onClose();
    setText('');
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{title || 'Text'}</DialogTitle>
      <DialogContent>
        <Grid container>
          {editable ? (
            <RichTextField initial={text} onChange={setText} onDone={setText} editOpen hideButton savingTimeout={100} />
          ) : (
            <HtmlText value={text} />
          )}
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={done} hideDone={!editable || (required && !text)} />
    </RoundedDialog>
  );
}
