import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLinkedFiles } from '../../redux/actions';
import { fetchComments } from '../../redux/actions/comment';
import { fetchApprovalsByActivity } from '../../../../../redux/actions';
import { getMiniRevisions } from '../../redux/actions/revision';

import { formatRevision, formatDate } from '../../constants';

import { IconButton, Paper, useMediaQuery } from '@mui/material';
import { red, green, grey } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import User from '../../../../Global/Components/User';
import { TooltipTypography } from '../../../../Global/Components';
import CommentsSection from '../Comments';
import HistoryTable from './history';
import StatusIcon from './status';
import LinkTable from './link';
import SupportingDocsTable from './files';

import CloseIcon from '@mui/icons-material/Close';
import { getSignatureName } from '../../utils';
import ActionGroup from '../../../../Global/Fields/ActionGroup';

export default ({ document, open, handleClose }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  const { linkedFiles, revComments, revFetching, miniRevisions } = useSelector(({ dochub }) => dochub);
  const { approvals } = useSelector(({ approval }) => approval);

  useEffect(() => {
    if (!document) return;
    const id = document?._id;
    dispatch(fetchComments(id));
    dispatch(fetchApprovalsByActivity(id));
    if (document.document && document.document.hasOwnProperty('_id')) {
      const documentId = document?.document?._id;
      dispatch(getMiniRevisions(documentId, document.baseVersion));
      dispatch(getLinkedFiles({ sourceDocument: documentId, sourceIssue: id, status: 'published' }));
    }
  }, [dispatch, document]);

  const handleLink = (data) => {
    let url = `/${data.targetType}${data.targetType === 'process' ? 'es' : 's'}/${data.targetItem}`;
    if (data?.targetType === 'document') {
      url = `/DocLibrary/preview/${data.targetItem}`;
    }
    window.open(url, '_blank');
  };

  const getRevisionOptions = () => {
    return miniRevisions && miniRevisions.length > 0
      ? miniRevisions.map((x, index) => ({ label: `Version ${index + 1}`, value: index + 1 }))
      : [];
  };

  const getFinalApproval = (data) => {
    let result = data.userApprovals?.sort((x, y) => {
      const finalX = x.approvals?.find((a) => a.attempt === data.currentAttempt);
      const finalY = y.approvals?.find((a) => a.attempt === data.currentAttempt);
      if (!finalX || !finalY) return 0;
      return finalX?.createdAt - finalY?.createdAt;
    });
    return result[0];
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="revision-dialog-title"
      maxWidth={'xl'}
      fullWidth={true}
      fullScreen={isMobile}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '32px',
        },
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        sx={{
          padding: `30px 20px`,
        }}
      >
        <Grid
          item
          xs={12}
          sm={3}
          container
          direction="row"
          justifyContent="flex-end"
          sx={{ position: 'absolute', top: '10px', right: '1%' }}
        >
          <IconButton
            size="small"
            onClick={() => handleClose(false)}
            sx={{
              borderRadius: 0,
              '&.MuiIconButton-root:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} sm={5} container direction="row">
          <Grid
            item
            sm={12}
            component={Paper}
            sx={{
              borderRadius: '22px',
              boxShadow: '0px 0px 10px rgba(0,0,0,0.16)',
              padding: '20px',
              marginRight: '20px',
              marginBottom: '20px',
            }}
            container
            direction="column"
          >
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography color="textSecondary" variant="subtitle2">
                Created at: {formatDate(document?.createdAt, 'DD/MM/YYYY')}
              </Typography>
              <Typography color="primary" variant="h6">
                version:{' '}
                {formatRevision(
                  document?.baseVersion,
                  document?.revisionFormat,
                  document?.manualIssueControl,
                  document?.manualIssue,
                )}
              </Typography>
            </Grid>
            <Grid container direction={'row'} alignItems={'center'} sx={{ margin: '5px 0px' }}>
              <TooltipTypography variant="h4">{document?.title}</TooltipTypography>
            </Grid>
            <Divider sx={{ margin: '5px 0px' }} />
            <Grid
              container
              direction={'row'}
              justifyContent={'space-between'}
              sx={{ margin: '5px 0px' }}
              alignItems="center"
            >
              <Typography sx={{ fontWeight: 500 }}>Who started?</Typography>
              <User id={document.issuedBy} avatarSize={20} />
            </Grid>
            <Divider sx={{ margin: '5px 0px' }} />
            <Grid container direction={'column'} justifyContent={'space-between'} sx={{ margin: '5px 0px' }}>
              <Typography sx={{ fontWeight: 500 }}>Reason for change?</Typography>
              <Typography>{document.revisionReason}</Typography>
            </Grid>
            <Divider sx={{ margin: '15px 0px' }} />
            <Grid container direction={'column'} justifyContent={'space-between'} sx={{ margin: '5px 0px' }}>
              <Typography sx={{ fontWeight: 500 }}>What changed?</Typography>
              <Typography>{document.revisionChanges}</Typography>
            </Grid>
            <Divider sx={{ margin: '15px 0px' }} />
            <Grid container direction={'row'} justifyContent={'flex-start'} sx={{ margin: '5px 0px' }}>
              <Typography sx={{ fontWeight: 500 }} variant="h6">
                Change History
              </Typography>
            </Grid>
            <Grid container>
              <HistoryTable document={document} miniRevisions={miniRevisions} />
            </Grid>
            <Grid container direction={'row'} justifyContent={'flex-start'} sx={{ margin: '5px 0px' }}>
              <Typography sx={{ fontWeight: 500 }} variant="h6">
                Actions
              </Typography>
            </Grid>
            <Grid container>
              <ActionGroup
                activityInfo={{ entryId: document?._id, type: 'documentIssue' }}
                itemId={document?._id}
                editable={false}
              />
            </Grid>
            <Grid container direction={'row'} justifyContent={'flex-start'} sx={{ margin: '5px 0px' }}>
              <Typography sx={{ fontWeight: 500 }} variant="h6">
                Supporting Documents
              </Typography>
            </Grid>
            <Grid container>
              <SupportingDocsTable document={document} />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            component={Paper}
            sx={{
              borderRadius: '22px',
              boxShadow: '0px 0px 10px rgba(0,0,0,0.16)',
              padding: '20px',
              marginRight: '20px',
              marginBottom: '20px',
            }}
            container
            direction="column"
          >
            <Grid container direction={'row'} sx={{ margin: '5px 0px' }}>
              <Typography sx={{ fontWeight: 500 }} variant="h6">
                Links from this document
              </Typography>
            </Grid>
            <Divider sx={{ margin: '5px 0px' }} />
            {linkedFiles?.length === 0 && <Typography>No references available</Typography>}
            <Grid container>
              <LinkTable linkedFiles={linkedFiles} document={document} handleLink={handleLink} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={7} container direction="row">
          <Grid item container sm={12} justifyContent="space-between" sx={{ marginBottom: '15px' }}>
            <Grid
              item
              sm
              component={Paper}
              sx={{
                borderRadius: '22px',
                boxShadow: '0px 0px 10px rgba(0,0,0,0.16)',
                padding: '20px',
                marginRight: '20px',
                marginBottom: '20px',
              }}
              container
              direction="column"
              alignContent="flex-start"
            >
              <Grid container>
                <Typography variant={'h5'}> Reviews </Typography>
              </Grid>
              <Divider sx={{ margin: '5px 0px' }} />
              <Grid
                container
                direction={'row'}
                justifyContent={'space-between'}
                alignItems="center"
                sx={{ margin: '5px 0px' }}
              >
                <Typography sx={{ fontWeight: 500 }}>Who did the review?</Typography>
                <User id={document?.reviewers.sort((a, b) => b.submittedAt - a.submittedAt)[0]?.user} avatarSize={20} />
              </Grid>
              <Divider sx={{ margin: '5px 0px' }} />
              <Grid container direction={'row'} justifyContent={'space-between'}>
                <Typography sx={{ fontWeight: 500 }}>When was the review?</Typography>
                <Typography>
                  {formatDate(
                    document?.reviewers.sort((a, b) => b.submittedAt - a.submittedAt)[0]?.submittedAt,
                    'DD-MM-YYYY',
                  )}
                </Typography>
              </Grid>
              <Divider sx={{ margin: '15px 0px' }} />
              <Grid container direction={'column'} justifyContent={'space-between'} sx={{ margin: '5px 0px' }}>
                <Typography sx={{ fontWeight: 500 }}>What was the final review outcome?</Typography>
                <Typography sx={{ margin: '5px 0px' }}>
                  {document?.reviewers.sort((a, b) => b.submittedAt - a.submittedAt)[0]?.outcome}
                </Typography>
              </Grid>
              {document?.reviewers.sort((a, b) => b.submittedAt - a.submittedAt)[0]?.signature && (
                <Grid
                  container
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems="center"
                  sx={{ margin: '5px 0px' }}
                >
                  <Typography sx={{ fontWeight: 500 }}>Signature</Typography>
                  <Paper variant="outlined" sx={{ p: 1, borderRadius: 1, display: 'flex', alignItems: 'center' }}>
                    <img
                      style={{
                        width: '100%',
                        height: 75,
                        objectFit: 'contain',
                        userSelect: 'none',
                        pointerEvents: 'none',
                        verticalAlign: 'middle',
                      }}
                      src={document?.reviewers.sort((a, b) => b.submittedAt - a.submittedAt)[0]?.signature?.base64Data}
                      alt="signature"
                    />
                  </Paper>
                </Grid>
              )}
              {document?.reviewers.sort((a, b) => b.submittedAt - a.submittedAt)[0]?.signature && (
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                  sx={{ fontSize: 12, marginLeft: '15px' }}
                  align="right"
                >
                  {document?.reviewers.sort((a, b) => b.submittedAt - a.submittedAt)[0]?.signature && 'Signed '}{' '}
                  {formatDate(
                    document?.reviewers.sort((a, b) => b.submittedAt - a.submittedAt)[0]?.submittedAt,
                    'hh:mm a DD/MM/YYYY ',
                  )}{' '}
                  {document?.reviewers.sort((a, b) => b.submittedAt - a.submittedAt)[0]?.signature && 'by '}{' '}
                  {getSignatureName(document?.reviewers.sort((a, b) => b.submittedAt - a.submittedAt)[0])}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              sm
              component={Paper}
              sx={{
                borderRadius: '22px',
                boxShadow: '0px 0px 10px rgba(0,0,0,0.16)',
                padding: '20px',
                marginRight: '20px',
                marginBottom: '20px',
              }}
              container
              direction="column"
            >
              <Grid container>
                <Typography variant={'h5'}> Approvals </Typography>
              </Grid>
              <Divider sx={{ margin: '5px 0px' }} />
              <Grid
                container
                direction={'row'}
                justifyContent={'space-between'}
                alignItems="center"
                sx={{ margin: '5px 0px' }}
              >
                <Typography sx={{ fontWeight: 500 }}>Who did the approval?</Typography>
                {approvals && approvals?.userApprovals && approvals?.userApprovals.length > 0 && (
                  <User id={getFinalApproval(approvals)?.userId} avatarSize={20} />
                )}
              </Grid>
              <Divider sx={{ margin: '5px 0px' }} />
              <Grid container direction={'row'} justifyContent={'space-between'}>
                <Typography sx={{ fontWeight: 500 }}>When was it submitted for approval?</Typography>
                {approvals && <Typography>{formatDate(approvals?.decidedAt, 'DD-MM-YYYY')}</Typography>}
              </Grid>
              <Divider sx={{ margin: '15px 0px' }} />
              <Grid container direction={'column'} justifyContent={'space-between'} sx={{ margin: '5px 0px' }}>
                <Typography sx={{ fontWeight: 500 }}>What were the comments?</Typography>
                {approvals?.userApprovals
                  ?.filter((approval) => approval.userId === getFinalApproval(approvals).userId)
                  ?.map((userApproval) =>
                    userApproval?.approvals?.map((data) => (
                      <Grid
                        item
                        container
                        key={data._id}
                        sx={{
                          position: 'relative',
                          marginTop: '25px',
                          margin: '25px 0px',
                        }}
                      >
                        <Grid
                          item
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{
                            position: 'absolute',
                            top: '-18px',
                          }}
                        >
                          <User
                            id={userApproval.userId}
                            avatarSize={40}
                            buttonStyle={{ padding: '0px' }}
                            listItemStyles={{ marginTop: '-10px' }}
                            customUserPadding={3}
                          />
                          <StatusIcon status={data.state} avatarSize={32} />
                        </Grid>
                        <Grid
                          item
                          container
                          sx={{
                            padding: '24px 10px 10px 15px',
                            backgroundColor: (t) => (t.palette.mode === 'dark' ? t.palette.primary.main : grey[200]),
                            borderRadius: '10px',
                            margin: '6px',
                          }}
                        >
                          <Typography>{data.comment}</Typography>
                        </Grid>
                        <Grid item container>
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                            sx={{ fontSize: 12, marginLeft: '15px' }}
                          >
                            {formatDate(data.createdAt, 'hh:mm a DD/MM/YYYY')}{' '}
                          </Typography>
                        </Grid>
                        {data?.signature && (
                          <Grid
                            container
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems="center"
                            sx={{ margin: '5px 0px' }}
                          >
                            <Typography sx={{ fontWeight: 500 }}>Signature</Typography>
                            <Paper
                              variant="outlined"
                              sx={{ p: 1, borderRadius: 1, display: 'flex', alignItems: 'center' }}
                            >
                              <img
                                style={{
                                  width: '100%',
                                  height: 75,
                                  objectFit: 'contain',
                                  userSelect: 'none',
                                  pointerEvents: 'none',
                                  verticalAlign: 'middle',
                                }}
                                src={data?.signature?.base64Data}
                                alt="signature"
                              />
                            </Paper>
                          </Grid>
                        )}
                        {data?.signature && (
                          <Grid item container justifyContent="flex-end">
                            <Typography color="textSecondary" variant="subtitle2" sx={{ fontSize: 12 }} align="right">
                              {data?.signature && 'Signed '} {formatDate(data?.createdAt, 'hh:mm a DD/MM/YYYY')}{' '}
                              {getSignatureName(data)}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    )),
                  )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sm={12}
            component={Paper}
            sx={{
              borderRadius: '22px',
              boxShadow: '0px 0px 10px rgba(0,0,0,0.16)',
              padding: '20px',
              marginRight: '20px',
              marginBottom: '20px',
            }}
            container
            direction="column"
          >
            <Grid container>
              <Typography variant={'h5'} sx={{ fontWeight: 500 }}>
                {' '}
                Comments{' '}
              </Typography>
            </Grid>
            <Divider sx={{ margin: '5px 0px' }} />
            <CommentsSection
              loading={revFetching}
              comments={revComments}
              filterPosition={'flex-end'}
              disabled={true}
              fullWidth={true}
              versions={getRevisionOptions()}
              variant={'v2'}
            />
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};
