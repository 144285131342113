import { isArray, isEmpty, isNumber } from 'lodash';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { FormatDateFull, FormatOnlyDate, FormatOnlyTime, isHex24, isUuid, VisualiseSearch } from '../Functions';
import { AttachMoneyRounded, CurrencyPoundRounded, EuroRounded, LabelRounded } from '@mui/icons-material';
import { PercentRounded } from '@mui/icons-material';
import { Table, TableHead, TableRow, TableCell, TableBody, Grid, Typography } from '@mui/material';
import { UserChip, UserGroup } from '../Components';
import { amber, grey } from '@mui/material/colors';
import { Box } from '@mui/system';

const ISO_DATE_FORMAT = 'YYYY-MM-DD';
const textTypes = ['textArea', 'text', 'email', 'phone'];

export default function MiniField({ field, initial, direction = 'row', emptyAsNull, searchPattern, ...props }) {
  const [text, setText] = useState('');
  const [number, setNumber] = useState(null);
  const [date, setDate] = useState(null);
  const [dropboxValue, setDropboxValue] = useState('');
  const [tickboxValues, setTickboxValues] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [dataSetRows, setDataSetRows] = useState([]);
  const [statusValue, setStatusValue] = useState('');

  useEffect(() => {
    const value = initial?.value;
    if (!field.fieldType) return;
    if (['textArea', 'text', 'email', 'phone'].includes(field.fieldType)) {
      setText(value || '');
    }
    if (field.fieldType === 'number') {
      setNumber(isNumber(value) ? value : null);
    }

    if (field.fieldType === 'date') {
      setDate(value && moment(value, true).isValid() ? moment(value).format(ISO_DATE_FORMAT) : null);
    }
    if (field.fieldType === 'datetime') {
      setDate(value && moment(value, true).isValid() ? moment(value) : null);
    }
    if (field.fieldType === 'dropbox' || field.fieldType === 'weightedList') {
      setDropboxValue(isUuid(value) ? value : '');
    }
    if (field.fieldType === 'tickbox') {
      setTickboxValues(isArray(value) && value.every(isUuid) ? value : []);
    }
    if (field.fieldType === 'user') {
      setUserIds(isArray(value) ? value : isHex24(value) ? [value] : []);
    }
    if (field.fieldType === 'dataSet') {
      if (isArray(initial?.rows)) {
        setDataSetRows(initial.rows);
      }
      if (isArray(initial?.value?.rows)) {
        setDataSetRows(initial.value.rows);
      }
    }
    if (field.fieldType === 'status') {
      setStatusValue(isUuid(value) ? value : '');
    }
  }, [initial, field]);

  let value = null;

  if (textTypes.includes(field.fieldType)) {
    value = text ? (
      <Typography style={{ whiteSpace: 'pre-wrap' }}>
        {searchPattern ? VisualiseSearch(text, searchPattern) : text}
      </Typography>
    ) : null;
  }

  if (field.fieldType === 'number') {
    value =
      number != null ? (
        <>
          {number != null && (
            <>
              {field.numberOptions?.format === 'dollar' && <AttachMoneyRounded fontSize="small" />}
              {field.numberOptions?.format === 'pound' && <CurrencyPoundRounded fontSize="small" />}
              {field.numberOptions?.format === 'euro' && <EuroRounded fontSize="small" />}
            </>
          )}

          <Typography>{number}</Typography>
          {number != null && field.numberOptions?.format === 'percent' && <PercentRounded fontSize="small" />}
        </>
      ) : null;
  }

  if (field.fieldType === 'time') {
    value = date ? <Typography style={{ fontWeight: 500 }}>{FormatOnlyTime(date)}</Typography> : null;
  }

  if (field.fieldType === 'date') {
    value = date ? <Typography style={{ fontWeight: 500 }}>{FormatOnlyDate(date)}</Typography> : null;
  }

  if (field.fieldType === 'datetime') {
    value = date ? <Typography style={{ fontWeight: 500 }}>{FormatDateFull(date)}</Typography> : null;
  }

  if (field.fieldType === 'dropbox' || field.fieldType === 'weightedList') {
    const selectedOption = isArray(field.options) ? field.options.find((x) => x.id === dropboxValue) : null;
    value = selectedOption ? (
      <Typography style={{ fontWeight: 500 }}>
        {searchPattern ? VisualiseSearch(selectedOption.text, searchPattern) : selectedOption.text}
      </Typography>
    ) : null;
  }

  if (field.fieldType === 'status') {
    const selected = statusValue ? field.options?.find((x) => x.id === statusValue) : null;
    value = (
      <Typography style={{ fontWeight: 500, color: selected?.color }}>{selected ? selected.text : '-'}</Typography>
    );
  }

  if (field.fieldType === 'tickbox') {
    if (isEmpty(tickboxValues)) {
      value = null;
    } else {
      const selected = field.options.filter((x) => tickboxValues.includes(x.id)).map((x) => x.text);
      value = (
        <Typography>
          {searchPattern ? VisualiseSearch(selected.join(', '), searchPattern) : selected.join(', ')}
        </Typography>
      );
    }
  }

  if (field.fieldType === 'user' && !isEmpty(userIds)) {
    value =
      userIds.length === 1 ? (
        <UserChip size="small" id={userIds[0]} color="default" />
      ) : (
        <UserGroup ids={userIds} avatarSize={30} />
      );
  }

  if (field.fieldType === 'dataSet' && !isEmpty(dataSetRows)) {
    if (field.dataSetParams.columns.length === 1) {
      const columnId = field.dataSetParams.columns[0].id;
      const selected = dataSetRows.map((x) => x[columnId]);
      value = <Typography>{selected.join(', ')}</Typography>;
    } else {
      value = (
        <Table size="small" style={{ width: 'fit-content' }}>
          <TableHead>
            <TableRow>
              {field.dataSetParams.columns.map((column) => (
                <TableCell
                  style={{ fontWeight: 700, borderBottom: `2px solid ${grey[700]}`, fontSize: 16 }}
                  key={column.id}
                >
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSetRows.map((row) => (
              <TableRow key={row.id}>
                {field.dataSetParams.columns.map((column) => (
                  <TableCell key={`${column.id}#${row.id}`}>{row[column.id]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }
  }

  if (emptyAsNull && (value == null || value === '')) {
    return null;
  }

  return (
    <Grid container alignItems={'center'} spacing={1} direction={direction} {...props}>
      <Grid item>
        <Box sx={{ alignItems: 'center', display: 'flex', flexWrap: 'nowrap' }}>
          <LabelRounded sx={{ color: amber[500], mr: 1 }} />
          <Typography sx={{ fontWeight: 600 }}>{field.title}</Typography>
        </Box>
      </Grid>
      <Grid item>{value || '-'}</Grid>
    </Grid>
  );
}
