import React, { useState, useEffect, useRef } from 'react';
import { List, ListItem, ListItemAvatar, ListItemText, ListItemIcon, Avatar, ListItemButton } from '@mui/material';
import { Grid, Badge, DialogContent, IconButton, Typography, Tooltip } from '@mui/material';
import UserManager from '../UserManager';
import StateManager from '../StateManager';
import Skeleton from '@mui/material/Skeleton';
import RoundedDialog from './RoundedDialog';
import ChatIcon from '@mui/icons-material/Chat';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { FormatDate } from '../Functions';
import { green } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { isFunction } from 'lodash';

const userId = localStorage.getItem('_id');

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#2196f3',
    color: '#2196f3',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      border: '1px solid currentColor',
      content: '""',
    },
  },
}));

export default function User({
  id,
  clickable = true,
  disableChatButton,
  onlyAvatar,
  fullWidth,
  action,
  additionalText,
  additionalTextStyles = {},
  listItemStyles = {},
  onClick,
  avatarSize = 40,
  buttonStyle,
  markPortal,
  selected = false,
  customUserPadding = null,
  withTickIcon,
  ...props
}) {
  const [user, setUser] = useState({});
  const [open, setOpen] = useState(false);
  const suspended = Boolean(user && user.suspendedAt);
  const deleted = Boolean(user && user.deletedAt);
  const inactive = suspended || deleted;
  const subscription = useRef();

  useEffect(() => {
    return () => {
      if (subscription.current) {
        subscription.current.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (id && typeof id === 'string') {
      setUser(UserManager.resolveUser(id));

      if (subscription.current) {
        subscription.current.unsubscribe();
      }
      subscription.current = UserManager.subscribeToUser(id, setUser);
    }
  }, [id]);

  const fontSize = Math.round(avatarSize / 2);
  const padding = Math.round(avatarSize / 8);

  const ItemComponent = clickable ? ListItemButton : ListItem;

  return (
    <Grid container={fullWidth} {...props} onClick={(e) => clickable && e.stopPropagation()}>
      <ItemComponent
        selected={selected}
        onClick={(e) => {
          if (!clickable) return;

          if (isFunction(onClick)) {
            onClick();
          } else {
            setOpen(true);
          }
        }}
        style={{
          borderRadius: 5,
          padding: `${padding}px ${padding * 2}px`,
          width: fullWidth ? '100%' : 'auto',
          ...buttonStyle,
        }}
      >
        {onlyAvatar && (
          <StyledBadge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            variant="dot"
            invisible={user.online !== true || inactive}
          >
            <Avatar
              src={inactive ? undefined : user.avatar}
              style={{
                background: inactive ? undefined : user.avatarColor,
                width: avatarSize,
                height: avatarSize,
                fontSize,
              }}
            >
              {user.avatarLetters}
            </Avatar>
          </StyledBadge>
        )}
        {!onlyAvatar && (
          <>
            <ListItemAvatar
              style={{ minWidth: 'auto', paddingRight: customUserPadding ? customUserPadding : padding * 3 }}
            >
              {user._id ? (
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  variant="dot"
                  invisible={user.online !== true || inactive}
                >
                  <Avatar
                    src={inactive ? undefined : user.avatar}
                    style={{
                      background: inactive ? undefined : user.avatarColor,
                      width: avatarSize,
                      height: avatarSize,
                      fontSize,
                    }}
                  >
                    {user.avatarLetters}
                  </Avatar>
                </StyledBadge>
              ) : (
                <Skeleton variant="circular" width={avatarSize} height={avatarSize} />
              )}
            </ListItemAvatar>

            {user._id ? (
              <>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography
                      noWrap
                      style={{ fontSize: 16, ...additionalTextStyles }}
                      color={inactive ? 'textSecondary' : 'textPrimary'}
                    >
                      {user.fullName + (additionalText || '')}{' '}
                      {markPortal && user?.access === 'portal' ? <b style={{ color: green[600] }}>portal</b> : ''}
                    </Typography>
                  }
                  style={listItemStyles}
                />
                {action && <Grid sx={{ ml: 1 }}>{action}</Grid>}
              </>
            ) : (
              <div>
                <Skeleton width={100} />
                <Skeleton width={50} />
              </div>
            )}
          </>
        )}
      </ItemComponent>

      {clickable && user._id && open && (
        <RoundedDialog open maxWidth="xs" fullWidth onClose={() => setOpen(false)}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid container item alignItems="center">
                <Grid item lg={5} md={5} sm={12}>
                  <Avatar
                    src={inactive ? undefined : user.avatar}
                    style={{
                      background: inactive ? undefined : user.avatarColor,
                      width: 140,
                      height: 140,
                      fontSize: 55,
                    }}
                  >
                    {user.avatarLetters}
                  </Avatar>
                </Grid>
                <Grid item lg={7} md={7} sm={12}>
                  <Typography
                    variant="h5"
                    style={{ marginBottom: 5, wordBreak: user.external ? 'break-word' : undefined }}
                    color={inactive ? 'textSecondary' : 'textPrimary'}
                  >
                    {user.fullName}
                  </Typography>
                  {user.access === 'portal' && !inactive && (
                    <Typography variant="h6" color="textSecondary" style={{ marginBottom: 5 }}>
                      Portal user
                    </Typography>
                  )}
                  {user.external && (
                    <Typography variant="h6" color="textSecondary" style={{ marginBottom: 5 }}>
                      External user
                    </Typography>
                  )}
                  {!user.external && !inactive && (
                    <>
                      {user.online ? (
                        <Typography style={{ color: '#2196f3' }}>online</Typography>
                      ) : (
                        <Typography style={{ fontSize: 15 }}>
                          {`${user.lastSeen ? 'last seen ' : ''}${FormatDate(user.lastSeen, true)}`}
                        </Typography>
                      )}
                    </>
                  )}
                  {suspended && !deleted && (
                    <Typography variant="h6" color="textSecondary" style={{ marginBottom: 5 }}>
                      User is suspended
                    </Typography>
                  )}
                  {deleted && (
                    <Typography variant="h6" color="textSecondary" style={{ marginBottom: 5 }}>
                      User is deleted
                    </Typography>
                  )}
                </Grid>
              </Grid>

              {!deleted && (
                <Grid container item>
                  <List>
                    {user.email && (
                      <ListItem disabled={inactive}>
                        <ListItemIcon>
                          <MailOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary={user.email} />
                      </ListItem>
                    )}
                    {user.mobile && (
                      <ListItem disabled={inactive}>
                        <ListItemIcon>
                          <PhoneIcon />
                        </ListItemIcon>
                        <ListItemText primary={user.mobile} />
                      </ListItem>
                    )}
                    {user.jobRole && (
                      <ListItem disabled={inactive}>
                        <ListItemIcon>
                          <WorkOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary={user.jobRole} />
                      </ListItem>
                    )}
                  </List>
                </Grid>
              )}

              {!disableChatButton && userId !== user._id && !user.external && !inactive && (
                <Grid container item alignItems="center" justifyContent="center">
                  <Grid item>
                    <Tooltip title="Send a message" placement="top" arrow>
                      <IconButton
                        onClick={(e) => {
                          setOpen(false);
                          StateManager.selectChat(user._id);
                        }}
                      >
                        <ChatIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>

                  {localStorage.getItem('access') !== 'portal' && (
                    <Grid item style={{ marginLeft: '1rem' }}>
                      <Tooltip title="Send a task" placement="top" arrow>
                        <IconButton
                          onClick={(e) => {
                            StateManager.setNewTask({ userId: user._id });
                          }}
                        >
                          <WorkOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </DialogContent>
        </RoundedDialog>
      )}
    </Grid>
  );
}
